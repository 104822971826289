<template>
  <div class="content-body">
    <div class="row mt-2 mg-sm-0 no-print">
      <div class="col-6">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">General Report</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="#">Assets</a>
                </li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Day Wise
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Assets Report</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-6 d-flex justify-content-end pr-2 pb-2">
        <div class="bg-theam-secondary d-flex m-2 p-1">
          <div class="report-tool-icon mr-3 ml-1">
            <i class="fas fa-print" title="Print" @click="print"></i>
          </div>
          <div class="report-tool-icon mr-1">
            <i class="fas fa-download" @click="download"></i>
          </div>
        </div>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head pt-0">
      <div class="row">
        <div class="filter-input col-md-12 d-block mr-0 pr-0">
          <select v-model="params.pageIndex" @change="searchFilter" class="mr-2 mg-t-5">
            <option value="15">15</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
          </select>
          <select class="mr-2 mg-t-5" v-model="params.session_year">
            <option
              v-for="(year, index) in sessionYears"
              :key="index"
              :value="year"
            >
              {{ year }}/{{ parseInt(year.substring(year.length - 2)) + 1 }}
            </option>
          </select>
           <select v-model="params.branch" class="filter-input mr-2 mg-t-5" @change="searchFilter">
            <option value="">All Branch</option>
            <option
              v-for="(branch, index) in assetDataAttributes.branchs"
              :key="index"
              :value="branch.id"
            >
              {{ branch.name }}
            </option>
          </select>
          <select
            v-model="params.type"
            name="type"
            class="filter-input mr-2 mg-t-5"
            @change="searchFilter"
          >
            <option value="">All Assets</option>
            <option
              v-for="type in assetDataAttributes.accounts_types"
              :key="type.id"
              :value="type.id"
            >
              {{ type.title }}
            </option>
          </select>
          <select
            v-model="params.account"
            class="filter-input mr-2 mg-t-5"
            @change="searchFilter"
          >
            <option value="">All Account</option>
            <option
              v-for="(account, index) in assetDataAttributes.accounts"
              :key="index"
              :value="account.id"
            >
              {{ account.account_name }}
            </option>
          </select>

          <div class="float-right mr-3 mg-t-5">
            <select
              class="filter-input ml-2 float-right"
              v-model="route"
              @change="filterReportType"
            >
              <option value="/general-report/assets">Account Wise</option>
              <option value="/general-report/assets/branch-wise">
                Branch Wise
              </option>
              <option value="/general-report/assets/day-wise">Day Wise</option>
              <option value="/general-report/assets/month-wise">
                Month Wise
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-theam-secondary table-search-head">
      <div>
        <span class="font-s tx-bold mr-2 tx-primary"
          >Opening Fixed Assets:  {{ parseDigitForSlip(assetDataAttributes.opening_balance) }}
        </span>
        <span class="font-s tx-bold mr-2 tx-primary"
          >Purchased Fixed Assets: 
          {{ parseDigitForSlip(assetDataAttributes.assets.total_assets_purchase) }}
        </span>
        <span class="font-s tx-bold mr-2 tx-primary"
          >Sales:  {{ parseDigitForSlip(assetDataAttributes.assets.total_assets_sale) }}</span
        >
        <span class="font-s tx-bold mr-2 tx-primary"
          >Closing Assets:  {{ parseDigitForSlip(assetDataAttributes.closing_balance) }}
        </span>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12 overflow-x-auto">
        <table class="table table2 table-hover" id="tableData">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N.</th>
              <th class="wd-25p">Date</th>
              <th class="wd-15p">Purchased</th>
              <th class="wd-20p">Sales Value</th>
              <th class="wd-15p">Sales Amt.</th>
              <th class="wd-15p">Loss</th>
            </tr>
          </thead>
          <tbody v-if="!loading && dayWiseDatas.length > 0">
            <tr v-for="(asset, index) in dayWiseDatas" :key="index">
              <th scope="row" class="table-start-item">
                {{ index + pageSerialNo }}.
              </th>
              <th>{{ asset.month }}</th>
              <td>{{ parseDigitForSlip(asset.purchase ? asset.purchase : 0) }}</td>
              <td>{{ parseDigitForSlip(asset.sale_value ? asset.sale_value : 0) }}</td>
              <td>{{ parseDigitForSlip(asset.sales_amount ? asset.sales_amount : 0) }}</td>

              <td>{{ parseDigitForSlip(asset.loss ? asset.loss : 0) }}</td>
            </tr>
          </tbody>
          <tbody v-else-if="error">
            <tr>
              <td colspan="11" class="text-center">{{ error_message }}</td>
            </tr>
          </tbody>
          <tbody v-else-if="!loading && dayWiseDatas == 0">
            <tr>
              <td colspan="11" class="text-center">No Records Found.</td>
            </tr>
          </tbody>
          <tbody v-else-if="loading">
            <tr>
              <td colspan="11" class="text-center">Loading...</td>
            </tr>
          </tbody>
        </table>
        <Paginate
          v-model="page"
          :pageCount="totalPageCount"
          :containerClass="'pagination'"
          :clickHandler="clickCallback"
          v-if="totalPageCount > 1"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Paginate from "vuejs-paginate";
import Services from "./Services/Services";
import _ from "lodash";
export default {
  components: {
    Paginate,
  },
  data() {
    return {
      sessionYears: ["2021"],
      route: this.$route.path,
      params: {
        pageIndex: 15,
        session_year: "2021",
        branch: "",
        account: "",
        searched: "",
        type: "",
        from: "",
        day: new Date().getDate(),
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
        to: "",
        offset: 0,
      },
      excel_params: {
        pageIndex: 15,
        session_year: "2021",
        branch: "",
        account: "",
        searched: "",
        type: "",
        from: "",
        day: new Date().getDate(),
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
        to: "",
        offset: 0,
      },
      pageSerialNo: 1,
      totalPageCount: "",
      page: 1,
      loading: false,
      error: false,
      error_message: "",
    };
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "today",
      "dataLists",
      "dataLists2",
      "dataLists9",
      "removeList",
      "eventMessage",
      "modalId",
    ]),
    ...mapGetters("assetsReports", ["dayWiseDatas", "assetDataAttributes"]),
  },
  methods: {
    filterReportType() {
      this.$router.push(this.route);
    },
    print(){
      window.print();
    },
    clickCallback: function (page) {
      if (page == 1) {
        this.pageSerialNo = 1;
      } else {
        this.pageSerialNo = (page - 1) * this.params.size + 1;
      }
      this.params.offset = (page - 1) * this.params.size;
      this.getData();
    },
    monthDayClicked(value) {
      if (value) {
        this.params.day = value.day;
        this.params.month = value.month;
        this.params.year = value.year;
      }
      //make api call
      this.getFilteredData();
    },
    getData() {
      this.getFilteredDataWithoutDates();
    },
    getFilteredData() {
      this.loading = true;
      this.params.from = "";
      this.params.to = "";
      let params = {
        pageIndex: this.params.pageIndex,
        branch: this.params.branch,
        account: "",
        type: this.params.type,
        today: this.params.day,
        month: this.params.month + 1,
        year: this.params.year,
        from: "",
        to: "",
        searched: this.params.searched,
        session_years: this.params.session_year,
        offset: this.params.offset,
      };
      this.excel_params = {
        pageIndex: this.params.pageIndex,
        branch: this.params.branch,
        account: "",
        type: this.params.type,
        today: this.params.day,
        month: this.params.month + 1,
        year: this.params.year,
        from: "",
        to: "",
        searched: this.params.searched,
        session_years: this.params.session_year,
        offset: this.params.offset,
      };
      this.ajaxRequest(params);
    },
    // without month component filter
    getFilteredDataWithoutDates() {
      this.loading = true;
      this.params.day = "";
      this.params.month = "";
      let params = {
        pageIndex: this.params.pageIndex,
        branch: this.params.branch,
        account: this.params.account,
        type: this.params.type,
        today: "",
        month: "",
        year: this.params.year,
        from: this.params.from,
        to: this.params.to,
        searched: this.params.searched,
        session_years: this.params.session_year,
        offset: this.params.offset,
      };
      this.excel_params = {
        pageIndex: this.params.pageIndex,
        branch: this.params.branch,
        account: this.params.account,
        type: this.params.type,
        today: "",
        month: "",
        year: this.params.year,
        from: this.params.from,
        to: this.params.to,
        searched: this.params.searched,
        session_years: this.params.session_year,
        offset: this.params.offset,
      };
      this.ajaxRequest(params);
    },
    ajaxRequest(params) {
      this.loading = true;
      Services.getMonthWiseReport(params)
        .then((res) => {
          this.$store.commit(
            "assetsReports/setDayWiseDatas",
            res.data.data.data
          );
          this.$store.commit(
            "assetsReports/setAssetDataAttributes",
            res.data.data.attributes
          );
          this.totalPageCount = res.data.data.total_no_of_pages;
        })
        .catch((err) => {
          this.error = false;
          let error = err.response.data.error_message;
          console.log(error);
          this.setNotification(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    searchFilter() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.params.offset = 0;
      this.getFilteredDataWithoutDates();
    },
    searchKeyword: _.debounce(function () {
      this.page = 1;
      this.pageSerialNo = 1;
      this.params.offset = 0;
      this.getFilteredDataWithoutDates();
    }, 1000),
    download() {
      Services.getMonthWiseExcel(this.excel_params)
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/vnd.ms-excel;charset=utf-8",
          });
          const elink = document.createElement("a");
          elink.download = "asset-month-wise-report.xlsx";
          elink.style.display = "none";
          elink.style.font = "none";
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href);
          document.body.removeChild(elink);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  beforeMount() {
    this.getData();
  },
};
</script>